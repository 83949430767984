import React from 'react'
import App from '../App.css';
import HomeButton from './HomeButton';

function PaymentPending() {
    return (
        <div>
          
          <div className="main-component">
       
         
            <div className="success-container">
              <img src="/images/pending-logo.avif" className="success-image" alt="" style={{ maxWidth: 250 }} />
              <p className="success-text">Your payment have been initiated, we'll reach out to you soon.</p>
            </div>
           
          </div>
          <HomeButton/>

        </div>
      );
}

export default PaymentPending