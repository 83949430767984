import React, { useState } from 'react';
import axios from 'axios';
import { v4 } from 'uuid';
import  '../style/Form.css'

const Form = () => {
  const [consumerMobileNo, setConsumerMobileNo] = useState('');
  const [consumerEmailId, setConsumerEmailId] = useState('');
  const [amount, setAmount] = useState(0);
  const [commisionAmount, setCommisionAmount] = useState(0);
  const [userTransactionId, setUserTransactionId] = useState(v4());
//   const baseurl = `https://apiinsurance.bharatprotection.com`;

//  console.log("base_url", process.env.REACT_APP_BASE_URL)

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     const formData = {
//       consumerMobileNo,
//       consumerEmailId,
//       amount: Math.ceil(amount) || "1",
//       commisionAmount: "0",
//       userTransactionId
//     };
//     axios.post('https://your-api-url.com/submit-form', formData)
//       .then(response => {
//         console.log(response);
//       })
//       .catch(error => {
//         console.error(error);
//       });
//   };
   const handlePayment = async (event) => {
    event.preventDefault();



    console.log("amount to be paid ", Math.ceil(amount));
    const data = {
        consumerMobileNo: consumerMobileNo,
        consumerEmailId: consumerEmailId,
        amount: Math.ceil(amount) || "1",
        commisionAmount: commisionAmount,
        userTransactionId: userTransactionId
    }
    console.log("this is were api is calling");


    // console.log(data);

    const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/payment/create-payment`, data)

    // console.log(res.status)

    if (res.status === 200) {

        console.log("result", res.data.result)

        const reqJson = {

            features: {

                enableAbortResponse: res.data.result.enableAbortResponse,

                enableExpressPay: res.data.result.enableExpressPay,

                enableInstrumentDeRegistration: res.data.result.enableInstrumentDeRegistration,

                enableMerTxnDetails: res.data.result.enableMerTxnDetails,

                enableNewWindowFlow: res.data.result.enableNewWindowFlow,

                showPGResponseMsg: res.data.result.showPGResponseMsg,

                showDownloadReceipt: res.data.result.showDownloadReceipt,

            },

            consumerData: {

                deviceId: res.data.result.deviceId, // possible values "WEBSH1" or "WEBSH2"

                token:

                    res.data.result.token,

                returnUrl:

                    res.data.result.returnUrl, // merchant response page URL

                responseHandler: handleResponse,

                paymentMode: res.data.result.paymentMode,

                merchantLogoUrl:

                    res.data.result.merchantLogoUrl, // provided merchant logo will be displayed

                merchantId: res.data.result.merchantId,

                currency: res.data.result.currency,

                consumerId: res.data.result.consumerId,

                consumerMobileNo: res.data.result.consumerMobileNo,

                consumerEmailId: res.data.result.consumerEmailId,

                txnId: res.data.result.txnId, // Unique merchant transaction ID

                items: [

                    {

                        itemId: res.data.result.itemId,

                        amount: res.data.result.amount,

                        comAmt: res.data.result.comAmt,

                    },

                ],

                customStyle: {

                    PRIMARY_COLOR_CODE: res.data.result.PRIMARY_COLOR_CODE, // merchant primary color code

                    SECONDARY_COLOR_CODE: res.data.result.SECONDARY_COLOR_CODE, // provide merchant's suitable color code

                    BUTTON_COLOR_CODE_1: res.data.result.txnId, // merchant's button background color code

                    BUTTON_COLOR_CODE_2: res.data.result.txnId, // provide merchant's suitable color code for button text

                },

            },

        };

        console.log( "windowjdjdj", reqJson)


        if (window.$ && window.$.pnCheckout) {
            console.log("window", window)

            window.$.pnCheckout(reqJson);

            if (reqJson.features.enableNewWindowFlow) {

                window.pnCheckoutShared.openNewWindow();

            }

        }

    }





};
function handleResponse(res) {
    console.log("ress",res)

    if (

        typeof res !== 'undefined' &&

        typeof res.paymentMethod !== 'undefined' &&

        typeof res.paymentMethod.paymentTransaction !== 'undefined' &&

        typeof res.paymentMethod.paymentTransaction.statusCode !== 'undefined' &&

        res.paymentMethod.paymentTransaction.statusCode === '0300'

    ) {

        // Success block
        // window.open("http://localhost:3001/");
        alert('Payment successful!');



    } else if (

        typeof res !== 'undefined' &&

        typeof res.paymentMethod !== 'undefined' &&

        typeof res.paymentMethod.paymentTransaction !== 'undefined' &&

        typeof res.paymentMethod.paymentTransaction.statusCode !== 'undefined' &&

        res.paymentMethod.paymentTransaction.statusCode === '0398'

    ) {

        // Initiated block

        alert('Payment initiated. Please wait for the confirmation.');

    } else {

        // Error block

        alert('Payment failed. Please try again later.');

    }

}

  return (
    <div className='container'>
    <div id="image-box">
    <img src='/images/image.png' id="image" alt="image"/>


    </div>
    <div id='form-box'>
    
    <form onSubmit={handlePayment}>
    <div className='labels-box'>
    <label>
        Mobile No:
      </label>
      
      <input type="tel" value={consumerMobileNo} onChange={(event) => setConsumerMobileNo(event.target.value)} />

    </div>
    <div className='labels-box'>
    <label>
        Email Id:
      </label>
    

      <input type="email" value={consumerEmailId} onChange={(event) => setConsumerEmailId(event.target.value)} />

    </div>
    <div className='labels-box'>
    <label>
        Amount:
      </label> 
      

      <input  type="number" value={amount?amount:null} onChange={(event) => setAmount(event.target.value)} />

    </div>
          <button type="submit">Submit</button>
    </form>

    </div>

    </div>
    
  );
};

export default Form;