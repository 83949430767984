import React from 'react';
import '../style/HomeButton.css';

const HomeButton = () => {
  return (
    <button className="home-button" onClick={() => window.location.href = '/'}>
      Home
    </button>
  );
};

export default HomeButton;