// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-button {
    background-color: #16b; /* Green background */
    border: none; /* Remove borders */
    color: white; /* White text */
    padding: 7px 16px; /* Add some padding */
    text-align: center; /* Center text */
    text-decoration: none; /* Remove underline */
    display: inline-block; /* Make the button inline */
    font-size: 16px; /* Increase font size */
    margin: 4px 2px; /* Add some margin */
    cursor: pointer; /* Add a pointer cursor on hover */
    border-radius: 12px; /* Rounded corners */
    transition: background-color 0.3s ease; /* Smooth transition */
  }
  
  .home-button:hover {
    background-color: #45a049; /* Darker green on hover */
  }
  `, "",{"version":3,"sources":["webpack://./src/style/HomeButton.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB,EAAE,qBAAqB;IAC7C,YAAY,EAAE,mBAAmB;IACjC,YAAY,EAAE,eAAe;IAC7B,iBAAiB,EAAE,qBAAqB;IACxC,kBAAkB,EAAE,gBAAgB;IACpC,qBAAqB,EAAE,qBAAqB;IAC5C,qBAAqB,EAAE,2BAA2B;IAClD,eAAe,EAAE,uBAAuB;IACxC,eAAe,EAAE,oBAAoB;IACrC,eAAe,EAAE,kCAAkC;IACnD,mBAAmB,EAAE,oBAAoB;IACzC,sCAAsC,EAAE,sBAAsB;EAChE;;EAEA;IACE,yBAAyB,EAAE,0BAA0B;EACvD","sourcesContent":[".home-button {\n    background-color: #16b; /* Green background */\n    border: none; /* Remove borders */\n    color: white; /* White text */\n    padding: 7px 16px; /* Add some padding */\n    text-align: center; /* Center text */\n    text-decoration: none; /* Remove underline */\n    display: inline-block; /* Make the button inline */\n    font-size: 16px; /* Increase font size */\n    margin: 4px 2px; /* Add some margin */\n    cursor: pointer; /* Add a pointer cursor on hover */\n    border-radius: 12px; /* Rounded corners */\n    transition: background-color 0.3s ease; /* Smooth transition */\n  }\n  \n  .home-button:hover {\n    background-color: #45a049; /* Darker green on hover */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
