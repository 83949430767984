import React from 'react';
import Form from './components/Form';
import { BrowserRouter, Routes, Route, Router, useNavigate } from "react-router-dom";
import PaymentSuccess from './components/PaymentSuccess';
import PaymentFailed from './components/PaymentFailed';
import PaymentPending from './components/PaymentPending';

const App = () => {
  return (
    <div className="App"> 
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Form/>} />
      <Route path="/payment-success" element={<PaymentSuccess />} />
      <Route path="/payment-failed" element={<PaymentFailed />} />
      <Route path="/payment-pending" element={<PaymentPending/>} />
      </Routes>
      </BrowserRouter>

    </div>
  );
};

export default App;