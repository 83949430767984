import React from 'react'
import HomeButton from './HomeButton';

function PaymentFailed() {
   
    return (
        <div>
          
          <div className="main-component">
          
           <div className="success-container">
          <img src="https://static.vecteezy.com/system/resources/thumbnails/004/968/453/small/failed-to-make-payment-by-credit-card-concept-illustration-flat-design-eps10-modern-graphic-element-for-landing-page-empty-state-ui-infographic-vector.jpg" className="success-image" alt="" />
          <p className="success-text">Your payment has been failed. </p>
        </div> 

          </div>
          <HomeButton/>

        </div>
      );
}

export default PaymentFailed